/* eslint-disable prefer-destructuring */
import { InferGetServerSidePropsType } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { TourType } from '@/_models/Tour';
import LoadingProgress from '@/components/common/LoadingProgress';
import { getPageTour } from '@/services/tour';
import { getSlug } from '@/utils/getSlug';
import PageHeader from '../../../components/common/PageHeader';

const RegularTour = dynamic(() => import('../../../components/RegularTour'), {
  loading: () => <LoadingProgress />,
  ssr: false,
});

const getParams = (slug: string | string[]) => {
  // renderType
  // 0 : /tour/:slug
  // 1 : /token/:token
  // 2 : /tour/:slug/:videoType/:rule/:agentId/:chatToken
  // 2 : /tour/:slug/:videoType/:rule/:chatToken
  let matterId;
  let videoType;
  let rule;
  let agentId;
  let room;
  let renderType = 2;

  if (slug?.length === 1 || (slug.length > 1 && slug[1] === 'processOrder') || (slug.length > 1 && slug[1] === 'book') || (slug.length > 1 && slug[1] === 'login')) {
    matterId = slug[0];
    renderType = 0;
  } else if (slug?.length === 4) {
    matterId = slug[0];
    videoType = slug[1];
    rule = slug[2];
    room = slug[3];
  } else if (slug?.length === 5) {
    matterId = slug[0];
    videoType = slug[1];
    rule = slug[2];
    agentId = slug[3];
    room = slug[4];
  } else {
    // eslint-disable-next-line no-console
    console.log('Wrong url, ask Boaz how to behave');
  }
  return {
    matterId,
    videoType,
    rule,
    agentId,
    room,
    renderType,
  };
};

export const getServerSideProps = async (context: { query: { slug: any; pmeToken: any }; req: any }) => {
  const slug = getSlug(context.query.slug);
  const pageInfo = await getPageTour({
    slug,
    mode: TourType.Regular,
    pmeToken: context.query.pmeToken,
    origin: context.req.headers.host,
  });
  const data = JSON.stringify(pageInfo);

  return {
    props: {
      data,
    },
  };
};

const IndexPage = ({ data }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { title, description, tourImage, website, fbPixel, fbAppId, faviconUrl, htmlCode, tourData, tags, matterTags, charset } = JSON.parse(data);

  const router = useRouter();
  const { matterId, renderType, videoType, rule, agentId, room } = getParams(router.query.slug);

  return (
    <PageHeader
      title={title}
      description={description}
      slug={matterId}
      tourImage={tourImage}
      website={website}
      fbPixel={fbPixel}
      fbAppId={fbAppId}
      faviconUrl={faviconUrl}
      htmlCode={htmlCode}
      type="tour"
      charset={charset}
    >
      <RegularTour
        tourData={tourData}
        tags={tags}
        matterTags={matterTags}
        tourImage={tourImage}
        renderType={renderType}
        matterId={matterId}
        videoType={videoType}
        agentId={agentId || null}
        rule={rule}
        room={room}
        reactAws={process.env.REACT_APP_AWS}
        reactAppDomain={process.env.REACT_APP_DOMAIN}
        reactAppMatterPort={process.env.REACT_APP_MATTERPORT}
        reactPaypalClient={process.env.REACT_APP_PAYPAL_CLIENT}
      />
    </PageHeader>
  );
};

export default IndexPage;
